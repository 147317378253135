body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(255,255,255);
  background-repeat: no-repeat;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-circle  {
  width:20px;
  height:20px;
  border-radius: 50%;
  margin: auto;
  text-decoration: none;
}

.section{
  min-width: 230px;
  max-width: 100%;
  border: 1px solid #cccc;
  background-color: #ffffff;
  border-radius: 7px;
  padding: 15px 10px 15px 10px;
  margin: 15px 15px 15px 15px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.list-item {
  cursor: pointer;
  opacity: 0;
  animation: fade-in 900ms ease forwards;
}

.list-item:nth-child(2) {
  animation-delay: 100ms;
}

.list-item:nth-child(3) {
  animation-delay: 200ms;
}

.list-item:nth-child(4) {
  animation-delay: 300ms;
}

.list-item:nth-child(5) {
  animation-delay: 400ms;
}

.list-item:nth-child(6) {
  animation-delay: 500ms;
}

.list-item:nth-child(7) {
  animation-delay: 600ms;
}

.list-item:nth-child(8) {
  animation-delay: 700ms;
}

.list-item:nth-child(9) {
  animation-delay: 800ms;
}

.list-item:nth-child(10) {
  animation-delay: 900ms;
}

@keyframes fade-in {
  to {
    opacity: 1;
  }
}

.zoom {
  transition: transform .5s;
}

.zoom:hover {
  transform: scale(1.05);
}

.color1 {
  background:#00b848 !important;
}

.color2 {
  background: #7cd83a !important;
}

.color3 {
  background: #fbff00 !important;
}

.color4 {
  background: #ffc200 !important;
}

.color5 {
  background: #ff0000 !important;
}

.color6 {
  background: #ca0000 !important;
}

.color7 {
  background: #858585 !important;
}

.color8 {
  background: #dac15c !important;
}

.color9 {
  background: #ffcaa7 !important;
}

.color20 {
  background: #8f299b !important;
}

.color21 {
  background: #0087f5 !important;
}

.hr-text {
  border: 0;
  line-height: 1em;
  position: relative;
  text-align: center;
  height: 1.5em;
  font-size: 14px;
  font-weight: bold;
  margin: 30px 15px;
}

.hr-text::before {
  content: "";
  background: linear-gradient(to right, transparent, #e0e6f2, transparent);
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}

.hr-text::after {
  content: attr(data-content);
  position: relative;
  padding: 0 7px;
  line-height: 1.5em;
  color: #4e7ace;
  background-color: white;
}

.table thead th {
  background-color: #ffffff;
  color: #4e7ace;
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
  min-width: 110px;
}

table tr:nth-child(odd) td{
  background: #e0e6f2;
}

table tr:nth-child(even) td{
   background:#ffffff;
}

table tbody tr{
  font-size:13px;
}

.date {
  width: 120px;
  min-width: 120px !important;
  max-width: 120px !important;
  text-align: center;
  vertical-align: middle
}

.chartjs-render-monitor{
  cursor: pointer;
}

  @media (max-width: 576px) {
    .full-width {
      max-width: 100%;
      width: 100%;
      min-height: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
    }

    .full-height {
      height: auto;
      min-height: 100%;
      border-radius: 0;
    }
  }




